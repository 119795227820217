.validation-message::before {
  display: none;
}

.validation-message {
  padding: 0;
  background-color: white;
  color: red;
  margin-top: 0;
  display: flex;
  justify-content: left;
  margin-left: 7%;
}
